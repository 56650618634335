import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StatusIndicatorComponent } from '../../_elements/status/status-indicator/status-indicator.component';
import { ButtonComponent } from '../../form/button/button.component';
import { LxmDatePipe } from 'src/app/pipes/date.pipe';
import { RouterLink } from '@angular/router';
import { MatRipple } from '@angular/material/core';
import { TranslateEnumPipe } from 'src/app/pipes/translate-enum.pipe';
import { PriceValuePipe } from 'src/app/pipes/price-value.pipe';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
  standalone: true,
  imports: [
    StatusIndicatorComponent,
    ButtonComponent,
    LxmDatePipe,
    RouterLink,
    MatRipple,
    TranslateEnumPipe,
    PriceValuePipe,
  ],
})
export class OrderCardComponent implements OnInit {
  @Input() public data: any;

  @ViewChild(MatRipple) rippleTarget: MatRipple;

  constructor() {}

  public onClick() {
    this.rippleTarget.launch({
      centered: false,
      persistent: false,
    });
  }

  ngOnInit() {}
}
