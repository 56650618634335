import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PAGE_SIZE } from 'src/app/config/pagination';

// @Injectable()
// export class PurchaseOrderDataService {
//   public items: { productId: string; priceSchemeId: string }[] = [];
//   public offerId: string;
//   public supplierId: string;
// }

// @Injectable()
// export class SalesOrderDataService {
//   public productIds: string[] = [];
// }

@Injectable({ providedIn: 'root' })
export class OrdersService {
  public soData = {
    productIds: [],
  };

  public poData = {
    items: [],
    offerId: null,
    supplierId: null,
  };

  constructor(
    private _http: HttpClient,
    // private _poDataService: PurchaseOrderDataService,
    // private _soDataService: SalesOrderDataService,
  ) {}

  public getOrderCatalogue(req = {}) {
    return this._http.post(`api/OrderCatalogue`, req, {
      params: { $count: 'true', $top: `${PAGE_SIZE.DEFAULT}` },
    });
  }

  public getOrderCatalogueFormData() {
    return this._http.get(`api/OrderCatalogue/formData`);
  }

  public getSentPurchaseOrdersList(req = {}, params = {}) {
    return this._http.post(`api/Orders/PurchaseOrders`, req, {
      params: params,
    });
  }

  public getSentPurchaseOrdersSearchFormData() {
    return this._http.get(`api/Orders/PurchaseOrders/search/formData`);
  }

  public getIncomingPurchaseOrdersSearchFormData() {
    return this._http.get(`api/Orders/PurchaseOrders/incoming/formData`);
  }

  public getSentSalesOrdersSearchFormData() {
    return this._http.get(`api/Orders/SalesOrders/search/formData`);
  }

  public getIncomingSalesOrdersSearchFormData() {
    return this._http.get(`api/Orders/SalesOrders/incoming/search/formData`);
  }

  public getSentSalesOrdersList(req = {}, params = {}) {
    return this._http.post(`api/Orders/SalesOrders`, req, { params: params });
  }

  public getIncomingSalesOrdersList(req = {}, params = {}) {
    return this._http.post(`api/Orders/SalesOrders/incoming`, req, {
      params: params,
    });
  }

  public getIncomingPurchaseOrdersList(req = {}, params = {}) {
    return this._http.post(`api/Orders/PurchaseOrders/Incoming`, req, {
      params: params,
    });
  }

  public getDeliveryPlan() {
    return this._http.get(`api/Orders/DeliveryPlan`);
  }

  public getPurchaseOrderFormData() {
    var { items, offerId } = this.poData;
    this.poData.items = null;
    this.poData.offerId = null;

    var data = {
      items,
      offerId,
    };

    return this._http.post(`api/Orders/PurchaseOrders/FormData`, data);
  }

  public getPurchaseOrderData(id) {
    return this._http.get(`api/Orders/PurchaseOrders/${id}`);
  }

  public getPurchaseOrderCloneData(id) {
    return this._http.get(`api/Orders/PurchaseOrders/${id}/clone`);
  }

  public getSalesOrderFormData() {
    var productIds = this.soData.productIds;
    this.soData.productIds = null;

    var data = {
      productIds,
    };

    return this._http.post(`api/Orders/SalesOrders/FormData`, data);
  }

  public getSalesOrderData(id) {
    return this._http.get(`api/Orders/SalesOrders/${id}`);
  }

  public getSalesOrderCloneData(id) {
    return this._http.get(`api/Orders/SalesOrders/${id}/clone`);
  }

  public getProcurementInfo(procurementId, supplierId, orderDeliveryDate) {
    return this._http.post(
      `api/Orders/PurchaseOrders/Procurement/${procurementId}`,
      { supplierId, orderDeliveryDate },
    );
  }

  public getProductsCampaignData(req) {
    return this._http.post(
      `api/Orders/PurchaseOrders/productsCampaignData`,
      req,
    );
  }

  public savePurchaseOrder(orderId: string, req) {
    if (orderId) {
      return this._http.post(`api/Orders/PurchaseOrders/${orderId}`, req);
    }

    return this._http.post(`api/Orders/PurchaseOrders/New`, req);
  }

  public saveSalesOrder(orderId: string, req) {
    if (orderId) {
      return this._http.post(`api/Orders/SalesOrders/${orderId}`, req);
    }
    return this._http.post(`api/Orders/SalesOrders/New`, req);
  }

  public deleteSalesOrder(orderId: string) {
    return this._http.delete(`api/Orders/SalesOrders/${orderId}`);
  }

  public deletePurchaseOrder(orderId: string) {
    return this._http.delete(`api/Orders/PurchaseOrders/${orderId}`);
  }

  public sendPurchaseOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/PurchaseOrders/${orderId}/Send`, req);
  }

  public confirmSalesOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/SalesOrders/${orderId}/Confirm`, req);
  }

  public completeSalesOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/SalesOrders/${orderId}/Complete`, req);
  }

  public rejectPurchaseOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/PurchaseOrders/${orderId}/Reject`, req);
  }

  public cancelPurchaseOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/PurchaseOrders/${orderId}/Cancel`, req);
  }

  public cancelSalesOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/SalesOrders/${orderId}/Cancel`, req);
  }

  public rejectSalesOrder(orderId: string, req = {}) {
    return this._http.post(
      `api/Orders/SalesOrders/incoming/${orderId}/reject`,
      req,
    );
  }

  public requestClientConfirm(orderId: string, req = {}) {
    return this._http.post(
      `api/Orders/SalesOrders/${orderId}/requestClientConfirm`,
      req,
    );
  }

  public sendSalesOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/SalesOrders/${orderId}/send`, req);
  }

  public createSalesOrder(orderId: string, req = {}) {
    return this._http.post(
      `api/Orders/PurchaseOrders/${orderId}/ToSalesOrder`,
      req,
    );
  }

  public createPurchaseOrder(orderId: string, req = {}) {
    return this._http.post(
      `api/Orders/SalesOrders/incoming/${orderId}/ToPurchaseOrder`,
      req,
    );
  }

  public confirmPurchaseOrderChanges(orderId: string, req = {}) {
    return this._http.post(
      `api/Orders/PurchaseOrders/${orderId}/confirmChanges`,
      req,
    );
  }

  public confirmSalesOrderChanges(orderId: string, req = {}) {
    return this._http.post(
      `api/Orders/SalesOrders/${orderId}/confirmChanges`,
      req,
    );
  }
  public confirmPurchaseOrder(orderId: string, req = {}) {
    return this._http.post(`api/Orders/PurchaseOrders/${orderId}/confirm`, req);
  }

  public requestSupplierConfirm(orderId: string, req = {}) {
    return this._http.post(
      `api/Orders/PurchaseOrders/${orderId}/requestSupplierConfirm`,
      req,
    );
  }

  public setOrderOwner(orderId: string, req = {}) {
    return this._http.post(`api/Orders/${orderId}/Owner`, req);
  }

  public comment(orderId: string, comment: string) {
    return this._http.post<any>(`api/Orders/${orderId}/Comment`, {
      comment: comment,
    });
  }

  public rejectionComment(orderId: string, comment: string) {
    return this._http.post<any>(`api/Orders/${orderId}/rejectionComment`, {
      comment: comment,
    });
  }

  public getSalesOrderAvailableContacts(clientId: string, locationId: string) {
    const req = {
      clientId,
      locationId,
    };

    return this._http.post<any>(
      `api/Orders/SalesOrders/availableContacts`,
      req,
    );
  }

  public exportXls(orderId: string) {
    return this._http.get<any>(`api/orders/${orderId}/exportXls`);
  }

  public downloadXls(fileId: string) {
    // very bad for bigger files, as the whole blob
    // is cached to browser memory instead of directly streaming the download to disk
    return this._http.get(`api/orders/downloadXls/${fileId}`, {
      responseType: 'blob',
    });
  }

  public exportXml(orderId: string) {
    return this._http.get<any>(`api/orders/${orderId}/exportXml`);
  }

  public downloadXml(fileId: string) {
    // very bad for bigger files, as the whole blob
    // is cached to browser memory instead of directly streaming the download to disk
    return this._http.get(`api/orders/downloadXml/${fileId}`, {
      responseType: 'blob',
    });
  }
}
