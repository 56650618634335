<div
  class="flex w-full cursor-pointer flex-col gap-1"
  matRipple
  [matRippleDisabled]="true"
  #rippleTarget
  (click)="onClick()"
>
  <div class="flex w-full flex-col gap-1 px-5x py-3x" [routerLink]="data.id">
    <div class="flex flex-wrap justify-between">
      <!-- NAME -->
      <span class="font-display text-xs font-medium text-grey-100">{{
        data.orderNo
      }}</span>
      <!-- DATE -->
      <span class="text-xs text-grey-70">{{ data.date | displayDate }}</span>
    </div>
    <div class="flex flex-wrap justify-between">
      <!-- RECIPIENT -->
      <span class="text-sm text-grey-100">{{ data.supplier }}</span>
      <!-- STATUS -->
      <status-indicator
        class="flex-row-reverse"
        [range]="6"
        [status]="data.status"
        enum="PurchaseOrderStatus"
        [description]="data.status | translateEnum: 'PurchaseOrderStatus'"
      ></status-indicator>
    </div>
    <div class="flex flex-wrap items-center justify-between">
      <!-- PRODUCTS COUNT -->
      <span class="text-xs text-grey-100"> {{ data.itemsCount }} toodet</span>
      <!-- ORDER TOTAL -->
      <span class="text-display text-sm font-bold text-grey-100"
        >{{ data.sumTotal }} €</span
      >
    </div>
  </div>

  <div class="flex px-5x py-3x">
    <button
      appButton
      color="tertiary"
      class="min-w-full justify-between"
      label="Lisa kõik ostukorvi"
      iconPosition="right"
      icon="monochrome-save-cart.svg"
    ></button>
  </div>
</div>
