const getValue = (name: string) => {
  if (name && typeof window !== 'undefined' && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(name)) || null;
  }
  return null;
};

const PAGE_SIZE_OPTIONS_DEFAULT = [20, 40, 60, 80, 100];

export const PAGE_SIZE_OPTIONS = {
  DEFAULT: PAGE_SIZE_OPTIONS_DEFAULT,
};

export const PAGE_SIZE = {
  DEFAULT: PAGE_SIZE_OPTIONS_DEFAULT[0],
};
