import {
  Pipe,
  PipeTransform,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  TranslateService,
  TranslationChangeEvent,
  LangChangeEvent,
} from '@ngx-translate/core';
import { translate } from 'src/app/enum';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'translateEnum',
  pure: false, // required to update the value when the promise is resolved
  standalone: true,
})
export class TranslateEnumPipe implements PipeTransform, OnDestroy {
  private _value: string;
  private _lastKey: any;
  private _lastType: any;

  private _onTranslationChange: Subscription;
  private _onLangChange: Subscription;
  private _onDefaultLangChange: Subscription;

  constructor(
    private _translateService: TranslateService,
    private _ref: ChangeDetectorRef,
  ) {}

  public updateValue(key: any, type: any): void {
    this._value = translate(type, key, this._translateService);
    this._ref.markForCheck();
  }

  public transform(key: any, type: any) {
    // if we ask another time for the same key, return the last value
    if (key === this._lastKey && type === this._lastType) {
      return this._value;
    }

    // store the key, in case it changes
    this._lastKey = key;

    // store the type, in case it changes
    this._lastType = type;

    // set the value
    this.updateValue(key, type);

    // if there is a subscription to onLangChange, clean it
    this._dispose();

    // subscribe to onTranslationChange event, in case the translations change
    if (!this._onTranslationChange) {
      this._onTranslationChange =
        this._translateService.onTranslationChange.subscribe(
          (event: TranslationChangeEvent) => {
            if (event.lang === this._translateService.currentLang) {
              this.updateValue(key, type);
            }
          },
        );
    }

    // subscribe to onLangChange event, in case the language changes
    if (!this._onLangChange) {
      this._onLangChange = this._translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.updateValue(key, type);
        },
      );
    }

    // subscribe to onDefaultLangChange event, in case the default language changes
    if (!this._onDefaultLangChange) {
      this._onDefaultLangChange =
        this._translateService.onDefaultLangChange.subscribe(() => {
          this.updateValue(key, type);
        });
    }

    return this._value;
  }

  public ngOnDestroy(): void {
    this._dispose();
  }

  /**
   * Clean any existing subscription to change events
   */
  private _dispose(): void {
    if (typeof this._onTranslationChange !== 'undefined') {
      this._onTranslationChange.unsubscribe();
      this._onTranslationChange = undefined;
    }
    if (typeof this._onLangChange !== 'undefined') {
      this._onLangChange.unsubscribe();
      this._onLangChange = undefined;
    }
    if (typeof this._onDefaultLangChange !== 'undefined') {
      this._onDefaultLangChange.unsubscribe();
      this._onDefaultLangChange = undefined;
    }
  }
}
