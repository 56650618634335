<div class="indicator-container status--{{ progress }}">
  @for (status of [].constructor(range); track $index) {
    <span class="status-circle"></span>
  }
</div>
@if (description) {
  <div class="flex flex-1">
    <span class="line-clamp-1 font-text text-sm font-normal text-grey-100">
      {{ description | translate }}
    </span>
  </div>
}

@if (template) {
  <div [innerHTML]="template"></div>
}
